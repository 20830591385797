import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CPagingList,
  CNewsList,
  CPostCard,
  LPlan,
  CUtilityBtnList,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: {
    limit: number;
    skip: number;
    totalCount: number;
    current: number;
  };
}) => {
  const frontmatter = {
    title: 'お知らせ',
    description:
      '横浜ロイヤルパークホテル公式サイト。ホテルからのお知らせを掲載。｜ランドマークタワー内に位置する高層ホテル。52階以上に位置する全ての客室からはみなとみらいの夜景が一望。最上階の「シリウス」をはじめ、和洋中それぞれのレストランや鉄板焼のレストランも。記念日、ビジネス、観光など、さまざまなシーンでご利用いただけます。みなとみらい駅から徒歩約3分、桜木町駅から徒歩約5分。',
  };

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'INFORMATION',
            sub: 'お知らせ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/information/kv.png',
              },
              imgSp: {
                src: '/assets/images/information/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CNewsList
            data={infoChoice(
              infoGet(),
              [],
              pageContext.limit + pageContext.skip,
              pageContext.skip
            )}
          />
          <CPagingList
            totalCount={pageContext.totalCount}
            path="/information/"
            perPage={pageContext.limit}
            current={pageContext.current}
          />
          <div className="u_tar u_mt80">
            <Link
              to="https://www.royalparkhotels.co.jp/release/"
              target="_blank"
              rel="noopner noreferrer"
              className="u_linktext"
            >
              報道関係向けニュースリリースはこちら
              <i className="icon-blank"></i>
            </Link>
          </div>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;
